.NovalCardWrapper {
  height: 478px;
  border-bottom: 10px solid #F1F1F1;
  .page {
    // transform: translateX(-10%);
    .item {
      height: 168px;
      display: flex;
      margin-bottom: 12px;
      width: 550px;
      img {
        width: 124px;
        height: 168px;
        margin-right: 28px;
        border-radius: 12px;
      }
      .content {
        flex: 1;
        .name {
          color: #1a1a1a;
          font-size: 30px;
          margin-top: 10px;
          width: 380px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .desc {
          margin-top: 6px;
          height: 52px;
          width: 380px;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 24px;
          color: #898989;
        }
        .meta {
          display: flex;
          margin-top: 25px;
          align-items: center;
          .comment,
          .browse {
            width: 100px;
            display: flex;
            align-items: center;
            img {
              width: 28px;
              height: 24px;
              margin-right: 3px;
            }
            span {
              font-size: 24px;
              color: #CCCCCC;
            }
          }
          .time {
            flex: 1;
            color: #CCCCCC;
            font-size: 24px;
            // text-align: right
          }
        }
      }
    }
  }
  .swiperEnd {
    width: 100px;
  }
}
