.LifeCardWrapper {
  height: 360px;
  .content {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
      img {
        width: 75px;
        height: 75px;
      }
      div {
        margin-top: 15px;
        font-size: 30px;
        width: 90%;
        text-align: center;
        color: #1a1a1a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}