.MiniApp {
  @supports (padding-top: env(safe-area-inset-top)) {
    & {
      --safe-area-inset-top: env(safe-area-inset-top, 26px);
      padding-top: var(--safe-area-inset-top);
    }
  }
  box-sizing: content-box;
  margin-top: 88px;
  border-bottom: 1px solid #F1F1F1;
  height: 192px;
  touch-action: none;
  .page {
    display: flex;
    flex-wrap: wrap;
    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 31px;
      // width: 33.33333%;
      width: 50%;
      .subscript {
        position: absolute;
        color: #ffffff;
        width: 30px;
        height: 30px;
        font-size: 20px;
        left: 50%;
        margin-left: 12px;
        text-align: center;
        line-height: 30px;
        text-align: center;
        border-radius: 20px;
        background-color: red;
      }
      img {
        width: 88px;
        height: 88px;
      }
      div {
        font-size: 26px;
        color: #1a1a1a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
