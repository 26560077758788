:global {
  * {
    margin: 0;
    padding: 0;
  }

  img {
    content: normal!important;
  }

  .slider.am-carousel,
  .slider-frame {
    height: 100% !important;
  }

  html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-overflow-scrolling : touch;
  }

  body {
    -webkit-overflow-scrolling : touch;
  }

}

.App {
  width: 100%;
  background-color: #fff;
}

.mainMask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: -900;
}

.mainHeaderMask{
  position: fixed;
  top: 0;
  height: 50%;
  left: 0;
  right: 0;
  background-color: #FC5755;
  z-index: -1000;
}