.NewShopCard {
  border-bottom: 10px solid #f1f1f1;
  .banner {
    display: block;
    background-color: #f1f1f1;
    width: 690px;
    height: 320px;
    margin-left: 30px;
    border-radius: 2px;
  }
  .item {
    position: relative;
    margin-top: 24px;
    width: 240px;
    height: 325px;
    margin-left: 28px;
    overflow: hidden;
    .tag {
      position: absolute;
      background-color: #FFE535;
      color: #FF3535;
      font-size: 24px;
      padding: 5px;
      left: 10px;
      top: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-weight: bolder;
      &.type1 {
        background-color: #FF3535;
        color: #ffffff;
        transform: rotateZ(45deg);
        right: -40px;
        left: initial;
        top: 20px;
        // font-size: 30px;
        width: 150px;
        text-align: center;
      }
    }
    img {
      width: 240px;
      height: 240px;
      border-radius: 10px;
      background-color: #f1f1f1;
    }
    .more {
      box-sizing: border-box;
      width: 80px;
      height: 240px;
      font-size: 30px;
      padding: 60px;
      text-align: center;
      line-height: 40px;
      padding-top: 80px;
      color: #131313;
      // background-image: url(../images/shop/more.png);
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      // background-position: 24px 24px;
    }
    .desc {
      width: 240px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      color: #1A1A1A;
      font-size: 24px;
      margin-top: 8px;
    }
  }
}