.GameCardWrapper {
  height: 555px;
  // border-bottom: 14px solid #F1F1F1;
  .game {
    position: relative;
    width: 468px;
    margin-left: 30px;
    height: 110px;
    display: flex;
    margin-bottom: 30px;
    // transform: translateX(-11%);
    // margin-left: -9%;
    img {
      width: 110px;
      margin-right: 24px;
      border-radius: 14px;
    }
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 3px;
      .name {
        font-size: 30px;
        color: #1a1a1a;
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .desc {
        color: #898989;
        font-size: 24px;
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .hot {
        color: #898989;
        font-size: 24px;
      }
    }
    .play {
      position: absolute;
      width: 120px;
      height: 50px;
      background-size: 100%;
      top: 32px;
      right: -93px;
    }
    .swiperEnd {
      width: 100px;
    }
  }
}