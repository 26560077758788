.Shop {
  .banner {
    display: block;
    background-color: #f1f1f1;
    width: 750px;
    height: 348px;
  }

  .title {
    font-size: 30px;
    color: #1a1a1a;
    margin-left: 21px;
    margin-top: 40px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    flex-wrap: wrap;
  }

  .item {
    width: 340px;
    img {
      display: block;
      width: 340px;
      height: 200px;
      border-radius: 5px;
      margin-top: 20px;
      background-color: #f1f1f1;
    }

    .desc {
      width: 340px;
      height: 54px;
      line-height: 30px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      color: #1A1A1A;
      font-size: 24px;
      margin-top: 8px;
    }
  }
}