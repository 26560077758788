.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    position: relative;
    height: 95px;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    .title {
      font-size: 32px;
      color: #1A1A1A;
      font-weight: bold;
    }
    .extra {
      color: #898989;
      font-size: 24px;
      margin-right: 40px;
    }
    img {
      position: absolute;
      width: 32px;
      height: 32px;
      right: 30px;
      top: 50%;
      margin-top: -16px;
    }
  }
  .content {
    flex: 1;
    position: relative;
    .loading {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 50px;
      height: 50px;
      margin-top: -80px;
      margin-left: -25px;
      animation: rotate infinite 1s linear;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotateZ(0deg)
  }
  to {
    transform: rotateZ(360deg)
  }
}