.ClassCardWrapper {
  height: 485px;
  border-bottom: 10px solid #f1f1f1;
  .item {
    position: relative;
    width: 360px;
    margin-left: 30px;
    height: 350px;
    .imageBackground {
      position: absolute;
      left: 0;
      right: 0;
      top: 166px;
      height: 54px;
      background-color: rgba(0,0,0,.5);
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    img {
      display: block;
      position: relative;
      width: 360px;
      height: 220px;
      border-radius: 6px;
      background-color: #f1f1f1;
    }
    .meta {
      position: absolute;
      color: #FFFFFF;
      font-size: 24px;
      left: 22px;
      top: 180px;
    }
    .title {
      width: 360px;
      margin-top: 20px;
      font-size: 30px;
      color: #1a1a1a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .desc {
      margin-top: 10px;
      width: 360px;
      font-size: 24px;
      color: #898989;
      height: 54px;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
  }
}