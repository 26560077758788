.HeadlineCard {
  height: 179px;
  display: flex;
  align-items: center;
  border-top: 14px solid #f1f1f1;
  border-bottom: 14px solid #f1f1f1;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
    .name {
      width: 100%;
      color: #1A1A1A;
      font-size: 24px;
      margin-top: 17px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
    }
  }
}