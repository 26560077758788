.list {
  @supports (padding-top: env(safe-area-inset-top)) {
    & {
      --safe-area-inset-top: env(safe-area-inset-top, 26px);
      padding-top: var(--safe-area-inset-top);
    }
  }
  margin-top: 88px;
  .item {
    position: relative;
    display: flex;
    height: 190px;
    padding-top: 20px;
    &::after {
      position: absolute;
      top: -9px;
      left: 28px;
      right: 0;
      height: 1px;
      background-color: #f1f1f1;
      content: "" !important;
    }
    img {
      width: 245px;
      height: 150px;
      border-radius: 6px;
      margin-right: 24px;
      margin-left: 28px;
    }
    .content {
      flex: 1;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        width: 430px;
        color: #1A1A1A;
        font-size: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .desc {
        width: 430px;
        color: #898989;
        font-size: 24px;
        height: 54px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        margin-top: -16px;
      }
      .meta {
        color: #CCCCCC;
        font-size: 24px;
      }
    }
  }
}