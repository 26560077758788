.Navigation {
  .icon {
    position: absolute;
    width: 42px;
    height: 42px;
    right: 30px;
    bottom: 20px;
  }
  .mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 998;
    animation: fate-out .2s;
  }
  .dialog {
    @supports (margin-top: env(safe-area-inset-top)) {
      & {
        --safe-area-inset-top: env(safe-area-inset-top, 26px);
        margin-top: var(--safe-area-inset-top);
      }
    }
    position: fixed;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    left: 0;
    right: 0;
    top: 90px;
    background-color: #fff;
    z-index: 999;
    animation: scale .2s;
    transform-origin: top;
    &.animationEnd {
      animation: unscale .2s;
    }
    &::after {
      background-color: #fff;
      position: absolute;
      width: 20px;
      height: 20px;
      transform: rotateZ(45deg);
      right: 40px;
      top: -10px;
      font-size: 0;
    }
    .section {
      position: relative;
      .title {
        font-size: 30px;
        color: #1a1a1a;
        margin-top: 0px;
        text-align: left;
        margin-left: 26px;
        font-weight: bold;
      }
      .extra {
        position: absolute;
        color: #898989;
        font-size: 30px;
        right: 30px;
        top: 0px;
      }
      .content {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 88px;
            height: 88px;
            border-radius: 44px;
          }
          div {
            color: #1A1A1A;
            font-size: 26px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .empty {
          width: 100%;
          color: #c5c5c5;
          margin-top: -10px;
          font-size: 30px;
          text-align: center;
        }
      }
    }
  }
}

@keyframes fate-out {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scale {
  from {
    transform: scaleY(0)
  }
  to {
    transform: scaleY(1)
  }
}

@keyframes unscale {
  from {
    transform: scaleY(1)
  }
  to {
    transform: scaleY(0)
  }
}