.list {
  @supports (padding-top: env(safe-area-inset-top)) {
    & {
      --safe-area-inset-top: env(safe-area-inset-top, 26px);
      padding-top: var(--safe-area-inset-top);
    }
  }
  margin-top: 88px;
  .swiperWrapper {
    height: 320px;
    margin-top: 10px;
    padding-bottom: 10px;
    .banner {
      display: block;
      height: 300px;
      width: 600px;
      border-radius: 10px;
    }
  }
  .item {
    position: relative;
    display: flex;
    height: 190px;
    padding-top: 20px;
    &::after {
      position: absolute;
      bottom: 9px;
      left: 28px;
      right: 0;
      height: 1px;
      background-color: #f1f1f1;
      content: "" !important;
    }
    img {
      width: 150px;
      height: 150px;
      border-radius: 6px;
      margin-right: 30px;
      margin-left: 80px;
    }
    .content {
      flex: 1;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        width: 430px;
        height: 60px;
        line-height: 30px;
        color: #1A1A1A;
        font-size: 30px;
        overflow: hidden;
        -webkit-line-clamp: 2;
      }
      .desc {
        width: 430px;
        color: #898989;
        font-size: 24px;
        height: 54px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        margin-top: -16px;
      }
      .prise {
        display: flex;
        align-items: flex-end;
        margin-top: 10px;
        .prev {
          width: 200px;
          font-size: 24px;
          color: #CCCCCC;
          text-decoration: line-through;
        }
        .now {
          width: 200px;
          font-size: 30px;
          color: #FC5755;
        }
      }
    }
  }
}