.DiamondMall {
  .tip {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 80px;
    background-color: #FFE5E5;
    top: 83px;
    left: 0;
    right: 0;
    font-size: 30px;
    .button {
      width: 80px;
      height: 36px;
      background-color: #D43C3C;
      color: #ffffff;
      font-size: 24px;
      text-align: center;
      line-height: 36px;
      border-radius: 10px;
      margin-left: 16px;
      margin-right: 15px;
    }
  }
}