.header {
  @supports (padding-top: env(safe-area-inset-top)) {
    & {
      --safe-area-inset-top: env(safe-area-inset-top, 26px);
      padding-top: var(--safe-area-inset-top);
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: content-box;
  height: 83px;
  font-size: 36px;
  color: #ffffff;
  text-align: center;
  line-height: 83px;
  background-color: #FC5755;
  img.back {
    position: absolute;
    left: 10px;
    bottom: 20px;
    width: 40px;
    height: 40px;
  }
}