.ShopCardWrapper {
  height: 430px;
  border-bottom: 10px solid #f1f1f1;
  .item {
    margin-top: 10px;
    position: relative;
    width: 240px;
    height: 310px;
    margin-left: 28px;
    .tag {
      position: absolute;
      height: 30px;
      line-height: 28px;
      background-color: red;
      font-size: 22px;
      padding-left: 15px;
      padding-right: 15px;
      color: #ffffff;
      // border-radius: 10px;
      right: 0px;
      border-radius: 20px;
      margin-top: -10px;
      right: -20px;
      top: 0px;
    }
    img {
      width: 240px;
      height: 240px;
      border-radius: 10px;
      background-color: #f1f1f1;
    }
    .desc {
      display: block;
      width: 240px;
      height: 40px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #1A1A1A;
      font-size: 24px;
      margin-top: 12px;
    }
  }
}

.HistoryCardWrapper {
  height: 288px;
  border-bottom: 10px solid #f1f1f1;
  .item {
    width: 120px;
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    img {
      width: 120px;
      height: 120px;
      margin-bottom: 10px;
      border-radius: 14px;
    }
    div {
      width: 120px;
      color: #1A1A1A;
      font-size: 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
  }
}