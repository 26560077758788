.list {
  @supports (padding-top: env(safe-area-inset-top)) {
    & {
      --safe-area-inset-top: env(safe-area-inset-top, 26px);
      padding-top: var(--safe-area-inset-top);
    }
  }
  margin-top: 88px;
  .item {
    position: relative;
    display: flex;
    height: 162px;
    padding-top: 15px;
    box-sizing: border-box;
    .followButton {
      position: absolute;
      top: 56px;
      right: 32px;
      background-color: #FC5655;
      color: #ffffff;
      line-height: 50px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      border-radius:4px;
      &.disabled {
        color: #999999;
        background-color: #fff;
        border: 1px solid #999;
      }
    }
    &::after {
      position: absolute;
      top: -9px;
      left: 28px;
      right: 0;
      height: 1px;
      background-color: #f1f1f1;
      content: "" !important;
    }
    img {
      width: 120px;
      height: 120px;
      border-radius: 60px;
      margin-right: 28px;
      margin-left: 31px;
    }
    .content {
      flex: 1;
      height: 162px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // justify-content: space-between;
      .title {
        width: 250px;
        color: #4A6D9F;
        font-size: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: -15px;
      }
      .desc {
        width: 250px;
        color: #898989;
        font-size: 24px;
        height: 54px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 8px;
      }
      .meta {
        color: #CCCCCC;
        font-size: 24px;
        margin-top: 10px;
      }
    }
  }
}